@use "base"
#root
    position: relative
    @media (min-width: base.$lg-width)
        display: grid
        grid-template-columns: repeat(4, 1fr)

//Scroll Button
.scroll-button
    border-radius: 50%
    border: 1px solid base.$white
    position: fixed
    right: 10px
    bottom: 150px
    transition: right 0.5s
    cursor: pointer
    font-size: 20px
    padding: 1.3rem
    background-image: url("../assets/images/scroll-btn.png")
    background-color: #63636326
    backdrop-filter: blur(5px)
    background-position: center
    background-repeat: no-repeat
    background-size: 35%
    opacity: 0.2
